<template>

    <div class="w-full rounded-lg module p-2 flex flex-col overflow-hidden relative">

        <loader :loading="loading" />

        <div class="h-10 flex flex-row justify-start items-center py-2 overflow-hidden relative flex-none">
            <input type="text" class="w-full rounded-md h-full bg-body px-2 text-xs" v-model="searchInput">
            <div class="h-full w-10 flex flex-col justify-center items-center" @click="visibleFilters = true">
                <i class="mdi mdi-dots-vertical"></i>
            </div>
        </div>

        <div v-if="!loading && OpportunitiesList.data && (OpportunitiesList.data).length > 0" class="h-full relative overflow-auto no-scrollbar mt-2">

            <loader :loading="loadingmore" />


            <div v-if="OpportunitiesList.meta.last_page > OpportunitiesList.meta.current_page" v-infinite-scroll="loadMore" infinite-scroll-disabled="loading" infinite-scroll-distance="10" class="h-full overflow-auto no-scrollbar">

                <div class="flex flex-col" v-for="(opportunity,index) in OpportunitiesList.data" :key="index">
                    <OpportunityMobile :opportunity="opportunity" :sectors:="sectors" @selectOportunity="selectOportunity"/>
                </div>
            </div>

            <div v-else class="h-full overflow-auto no-scrollbar">

                <div class="flex flex-col" v-for="(opportunity,index) in OpportunitiesList.data" :key="index">
                    <OpportunityMobile :opportunity="opportunity" :sectors:="sectors" @selectOportunity="selectOportunity"/>
                </div>
            </div>

            <!-- <div class="h-12"></div> -->


        </div>

        <div v-else class="h-full flex flex-col justify-center items-center">
            <loader :loading="loading" />
        </div>
        
        <div v-if="OpportunitiesList.data.length == 0" class="h-full w-full flex flex-col justify-center items-center">
            <span class="">No existen oportunidades</span>
        </div>

        <div class="overflow-hidden bg-filters z-30 absolute bottom-0 left-0 w-full rounded-lg flex flex-col shadow-md" :class="{'h-full':visibleFilters, 'h-0': !visibleFilters}" style="transition: all .3s">

            <div class="h-8 flex flex-row justify-start items-center pl-2">

                <span class="text-sm font-semibold text-font-gray">Filters</span>

                <div class="h-full w-8 ml-auto flex flex-row jusity-center items-center" @click="visibleFilters = false">
                    <i class="mdi mdi-close"></i>
                </div>

            </div>

            <div v-if="OpportunitiesFilterList" class="flex-1 flex flex-col justify-start items-start py-2">

                <div class="h-auto w-full px-2 mb-2 flex items-center">
                    <div class="text-font-gray mr-2 w-16 text-xs">SMU</div>
                    <multiselect v-model="smuSelected" :multiple="true" class="text-xs"
                                    :disabled="ifdisabledSMU"
                                    :options="optionsSMU"  @search-change="asyncFind($event, 'competencyIdWithRegionId')"
                                    openDirection="bottom"
                                    label="filter_name"
                                    trackBy="filter_id"/>
                </div>

                <div class="w-full px-2 mb-2 flex items-center">
                    <div class="text-font-gray mr-2 w-16 text-xs">Status</div>
                    <multiselect v-model="statusFilter" :multiple="true"
                                    :options="OpportunitiesFilterList.filters.status"
                                    openDirection="bottom"
                                    label="filter_name"
                                    trackBy="filter_id" />
                </div>

                <div class="w-full px-2 mb-2 flex items-center">
                    <div class="text-font-gray mr-2 w-16 text-xs">Progress</div>
                    <multiselect v-model="probavilityFilter" :multiple="true"
                        :options="OpportunitiesFilterList.filters.probability"
                        openDirection="bottom"
                        label="filter_name"
                        trackBy="filter_id"/>
                </div>

                <div class="w-full px-2 mb-2 flex items-center">
                    <div class="text-font-gray mr-2 w-16 text-xs">Sector</div>
                    <multiselect v-model="sectorSelected" :multiple="true"
                        :options="optionsSector" :disabled="ifdisabledSector"
                        openDirection="bottom"
                        label="filter_name"
                        trackBy="filter_id"/>
                </div>
            </div>

        </div>

        <div v-if="opportunitySelected" class="absolute h-full w-full top-0 left-0 z-20 flex flex-col pb-2">

            <div class="h-full w-full bg-filters flex flex-col rounded-lg">

                <div class="h-auto p-2 flex flex-row justify-start items-center">
                    <div class="h-4 w-4 rounded-full mr-2 flex flex-col justify-center items-center" @click="opportunitySelected = false">
                        <i class="mdi mdi-close font-semibold text-xs text-aux"></i>
                    </div>
                    <span class="text-xs mr-2 font-semibold" :class="percentageColor(opportunitySelected.probability)">{{ opportunitySelected.probability }}%</span>
                    <span class="text-xs text-white font-semibold mr-auto">{{ opportunitySelected.opportunity_name }}</span>
                </div>

                <div class="h-auto py-2 mt-2">
                    <div class="h-auto flex flex-row justify-between items-center px-2">
                        <span class="text-3xs text-font-gray">{{ opportunitySelected.client_name }}</span>
                        <span class="text-3xs text-aux font-semibold text-right">{{ opportunitySelected.competency || 'N/A' }}</span>
                    </div>
                    <div class="h-auto flex flex-row justify-between items-center mt-2 pl-2 pr-1">
                        <span class="text-3xs text-font-gray">{{ opportunitySelected.ep_name }}</span>
                        <div class="rounded-full px-2 text-box text-3xs ml-2 font-semibold"
                            :style="'color:' + legendColors[opportunitySelected.sector_ambiciona] +
                            ';background-color:' + legendColors[opportunitySelected.sector_ambiciona] + '22'">
                            {{ opportunitySelected.sector_ambiciona }}
                        </div>
                    </div>
                    <div class="h-auto flex flex-row justify-start items-center px-2 mt-2">
                        <span class="text-3xs text-font-gray mr-2">Opportunity Value</span>
                        <span class="text-3xs text-white font-semibold mr-auto">{{ opportunitySelected.valor_precio | numberFormat() }}€</span>
                        <span class="text-3xs text-font-gray mr-2">Margin</span>
                        <span class="text-3xs text-white font-semibold">{{ opportunitySelected.margen | numberFormat(2) }}%</span>
                    </div>
                </div>

                <div class="flex-1 p-1 overflow-hidden">

                    <div class="h-full bg-module-bottom w-full overflow-hidden rounded-lg relative">

                        <div v-if="!loadingCommentary" class="flex flex-col h-full overflow-hidden">

                            <div v-if="messages.length > 0" class="h-full overflow-y-auto mobile-scrollbar p-2 flex flex-col-reverse justify-end">

                                <transition-group class="min-h-full flex flex-col justify-end " name="fadeAndSlide" tag="div">

                                    <div  class="" :key="'key-' + post.id" v-for="post in messages">

                                        <div class="box mb-2 py-2 relative flex flex-col" style="min-height: 9rem;">

                                            <div class="flex items-center mb-2 flex flex-row justify-start items-center">

                                                <div class="flex flex-col h-10 flex flex-col justify-between">

                                                    <span class="text-beige text-3xs">
                                                        {{ [post.date, 'YYYY-MM-DD'] | moment('DD / MM / YYYY') }}
                                                    </span>

                                                    <span class="text-white uppercase text-3xs">{{ post.commentable_type }}</span>

                                                    <div class="font-semibold text-xxs">{{ post.commentable_name }}</div>

                                                </div>

                                                <div class="absolute top-0 right-0 flex flex-row mt-2 mr-2">

                                                    <div class="ml-auto h-5 w-5 rounded-md flex-none header-btn-gray" @click="selectedEdit = post.id">
                                                        <i class="text-xs mdi mdi-pencil-outline" ></i>
                                                    </div>

                                                    <div class="h-5 w-5 rounded-md flex-none header-btn-gray" @click="selectedDelete = post.id">
                                                        <i class="text-xs mdi mdi-trash-can-outline" ></i>
                                                    </div>

                                                </div>

                                            </div>



                                            <div class="mb-2 text-xs text-font-light font-thin">{{ post.commentary }}</div>
                                            <div class="flex flex-row justify-end items-center items-center mt-auto">
                                                <el-tooltip class="item" effect="dark" :content="post.user_name" placement="top">

                                                    <div v-if="post.image" class="h-4 w-4 rounded-full" :style="'background-image: url(' + post.image + '); background-repeat: no-repeat; background-attachment: fixed; background-position: center;'"></div>

                                                    <div v-else class="w-auto px-2 flex flex-col justify-center items-center">
                                                        <vue-initials-img :name="post.user_name" class="rounded-full h-4"/>
                                                    </div>

                                                </el-tooltip>
                                            </div>

                                            <transition  name="listSubCats">
                                                <div class="absolute h-full w-full bg-gradient-module top-0 left-0 rounded-lg overflow-hidden"
                                                    v-if="selectedDelete == post.id">
                                                    <div class="header-btn-gray absolute h-6 w-6 right-0 mr-2 top-0 mt-2 z-40" @click="selectedDelete = false">
                                                        <i class="text-xs mdi mdi-close" ></i>
                                                    </div>
                                                    <div class="center-absolute">
                                                        <div class="mb-2">Are you Sure?</div>
                                                        <div>
                                                            <div @click="deleteComment(post.id)" class="btn-danger h-6 flex flex-col justify-center items-center mx-auto w-10/12 mb-2 inline-block">Delete</div>
                                                            <div @click="selectedDelete = false" class="btn-gray h-6 flex flex-col justify-center items-center mx-auto w-10/12 inline-block">Cancel</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </transition>

                                            <transition  name="listSubCats">
                                                <div class="absolute h-full w-full bg-gradient-module top-0 left-0 rounded-lg overflow-hidden"
                                                    v-if="selectedEdit === post.id">
                                                    <div class="header-btn-gray absolute h-6 w-6 right-0 mr-2 top-0 mt-2 z-40" @click="selectedEdit = false">
                                                        <i class="mdi mdi-close text-xs" ></i>
                                                    </div>
                                                    <div class="center-absolute p-4 flex flex-col items-end h-full">

                                                        <textarea class="border bg-gradient-module w-full mb-2 mt-4 h-full outline-none p-2 rounded-lg shadow-lg text-sm"
                                                            placeholder="Send message..." v-model="post.commentary"></textarea>

                                                        <div class="btn-aux inline-block mt-auto h-6 flex flex-col justify-center items-center mx-auto w-full" @click="editComment(post)">
                                                            <i class="mdi mdi-send"></i>
                                                        </div>

                                                    </div>
                                                </div>
                                            </transition>

                                        </div>

                                    </div>

                                </transition-group>
                            </div>

                            <div v-else-if="!loading" class="w-full h-full overflow-hidden">

                                <div class="h-full w-full flex flex-col justify-center items-center bg-grey-lighter">

                                    <span class="text-font-gray text-xs">No Comments</span>

                                </div>

                            </div>

                        </div>

                        <div v-else class="h-full relative">
                            <loader :loading="loadingCommentary" />
                        </div>

                    </div>

                </div>

                <div class="px-2 pt-2 flex items-end mb-2">
                    <textarea class="bg-gradient-module w-full mr-2 h-8 outline-none p-2 rounded-lg shadow-lg text-xs"
                        placeholder="Send message..." v-model="commentary"></textarea>
                    <div class="btn-aux h-8" @click="publishComment()">
                        <i class="mdi mdi-send"></i>
                    </div>
                </div>

            </div>

        </div>

    </div>

</template>

<script>

    import axios from "axios";

    const OpportunityMobile = () => import('@/components/Mobile/Pipeline/Opportunity.vue');
    import { state,actions } from '@/store';
    import Loader from '../../components/Globals/Loader.vue';

    const CancelTokenPipeline = axios.CancelToken;
    let cancel;

    export default {
        components: {
            OpportunityMobile,
            Loader
        },
        data() {
            return {
                data: [],
                query: "",
                results: "",
                OpportunitiesList: {},
                sectors: {},
                probavilityFilter: [],
                statusFilter: [],
                itemsPerPage: 60,
                page: 1,
                last_page:null,
                numberOfOpportunities: 0,
                loading: false,
                searchInput: '',
                sectorFilter: [],
                visibleFilters:false,
                smuFilter:[],
                OpportunitiesFilterList:false,
                statusFilters:false,
                probavilityFilters:false,
                opportunitySelected:false,
                commentary:'',
                loadingCommentary:false,
                messages:[],
                selectedEdit:false,
                selectedDelete:false,
                optionsSMU:[],
                smuSelected:[],
                sectorSelected:[],
                optionsSector:[],
                loadingmore:false

            }
        },
        computed: {
            call_params() {
                return {
                    ...this.params,
                    opportunity_name: this.param_pipeline_name,
                }
            },
            param_pipeline_name() {
                if (this.searchInput !== '') { return this.searchInput }
                else { return null }
            },
            params(){ 
                return state.globalParams 
            },
            oppParams() {
                let param = this.params
                if (this.$route.params.catId) {
                    param =  {...state.globalParams, ...{sectorAmbiciona: this.$route.params.catId} }
                }
                return param
            },
            probabilityParams() {
                if (this.probavilityFilter.length > 0) {
                    const result = []
                    for (let index = 0; index < this.probavilityFilter.length; index++) {
                        result.push(this.probavilityFilter[index].filter_id)
                    }
                    return result
                }
            },
            statusParams() {
                if (this.statusFilter.length > 0) {
                    const result = []
                    for (let index = 0; index < this.statusFilter.length; index++) {
                        result.push(this.statusFilter[index].filter_id)
                    }
                    return result
                }
            },
            topParams() {
                return {
                    ...state.globalParams,
                    probability: this.probabilityParams,
                    status: this.statusParams,
                    competencyIdWithRegionId: this.smuParams,
                    sector: this.sectorParams,
                    opportunity_name: this.param_pipeline_name,
                }
            },
            legendColors(){ 
                return this.$legendColorsBySector(this.sectors) 
            },
            smuParams(){
                let result = []

                if(state.globalParams.competencyIdWithRegionId){

                    result = state.globalParams.competencyIdWithRegionId
                    // this.getSMU();

                } else if (this.smuSelected.length > 0) {

                    for (let index = 0; index < this.smuSelected.length; index++) {
                        result.push(this.smuSelected[index].filter_id)
                    }

                }

                return result
            },
            ifdisabledSMU(){
                if(state.globalParams.competencyIdWithRegionId){
                    return true
                } else{
                    return false
                }
            },
            sectorParams() {
                let result = []

                if(state.globalParams.sector){
                    result = state.globalParams.sector
                } else if (this.sectorSelected.length > 0) {

                        for (let index = 0; index < this.sectorSelected.length; index++) {
                            result.push(this.sectorSelected[index].filter_id)
                        }

                    }

                return result
            },
            ifdisabledSector(){
                if(state.globalParams.sector){
                    return true
                } else{
                    return false
                }
            }

        },
        methods: {
            clear() {
                this.loading = false;
                this.results = "";
                this.query = "";
            },
            handleSearch: _.debounce(function() {
                this.preApiCall();
            }, 300),
            preApiCall() {
                if (cancel !== undefined) {
                    cancel();
                }
                this.query = {
                    ...this.topParams,
                    page: this.page
                }
                this.getOpportunitiesList(this.query);
            },
            loadMore() {

                if(!this.loadingmore){

                    if(this.page < this.OpportunitiesList.meta.last_page){

                        this.page++;
                        this.loadingmore = true;

                        this.axios({
                            method: "get",
                            url: "pipeline/opportunities",
                            cancelToken: new CancelTokenPipeline(function executor(c) {
                                cancel = c;
                            }),
                            params: { 
                                ...this.topParams,
                                page: this.page    
                            }
                        }).then(response => {

                            for (let index = 0; index < response.data.data.length; index++) {
                                this.OpportunitiesList.data.push(response.data.data[index])
                            }

                            this.loadingmore = false;

                        });
                    
                    }

                }

                // this.preApiCall()
            },
            getOpportunitiesList(query) {
                this.loading = true;

                if (query !== "") {
                    if (this.params) {
                        this.axios({
                            method: "get",
                            url: "pipeline/opportunities",
                            cancelToken: new CancelTokenPipeline(function executor(c) {
                                cancel = c;
                            }),
                            params: { ...query }
                        }).then(response => {
                            if (this.page === 1) {
                                this.OpportunitiesList = response.data
                            }
                            else {
                                this.OpportunitiesList.meta = response.data.meta;
                                for (let index = 0; index < response.data.data.length; index++) {
                                    this.OpportunitiesList.data.push(response.data.data[index])
                                }
                            }
                            this.loading = false
                        }).catch(err => {
                            this.loading = false
                            this.results = err.message;
                        })
                    }
                }
                else {
                    this.clear()
                }
            },
            list() {
                let list = this.OpportunitiesList.data || []
                if (this.probavilityFilter && this.probavilityFilter.length) {
                    list = this._.filter(list, (p) => {
                        return this._.includes(this.probavilityFilter, p.probability)
                    })
                }
                if (this.statusFilter && this.statusFilter.length) {
                    list = this._.filter(list, (p) => {
                        return this._.includes(this.statusFilter, p.status)
                    })
                }
                if (this.sectorFilter) {
                    list = this._.filter(list, (p) => {
                        return this._.includes(this.sectorFilter, p.sector_ambiciona)
                    })
                }
                this.numberOfOpportunities = list.length
                return this._.slice(list, 0, this.itemsPerPage * this.page)
            },
            getFilters(){
                if (this.params) {
                    this.axios.get('pipeline/opportunities_filters', { params: this.params })
                        .then(response => {
                            this.OpportunitiesFilterList = response.data
                            this.smuFiltered();
                            this.sectorFiltered();
                    })
                }
            },
            selectOportunity(opp){
                this.opportunitySelected = opp
            },
            percentageColor(percentage){

                switch (percentage) {
                    case '25':
                        return 'text-percentage-25'
                        break;
                    case '50':
                        return 'text-percentage-50'
                        break;
                    case '75':
                        return 'text-percentage-75'
                        break;
                    case '100':
                        return 'text-percentage-100'
                        break;
                }

            },
            publishComment(){

                this.loadingCommentary = true

                let mess = {
                    // ...this.params,
                    commentary: this.commentary,
                    project_id: this.opportunitySelected.opportunity_id,
                }

                this.axios.post('commentary/opportunities/' + this.opportunitySelected.opportunity_id + '/commentaries', mess)
                .then(response => {
                    this.loadingCommentary = false
                    this.commentary = ''
                    actions.setCommentsListen(!state.commentsListen)
                    // this.opportunitySelected = false
                    this.getComments(this.opportunitySelected)
                })

            },
            getComments(){
                this.loadingCommentary = true;

                this.axios.get('commentary/opportunities/' + this.opportunitySelected.opportunity_id + '/commentaries', { params: this.topParams})
                .then(response => {

                    this.response = response.data
                    this.messages = response.data.data.length ? response.data.data.reverse() : []
                    // this.commentsOpportuniti = response.data.data
                    this.loadingCommentary = false

                })
            },
            editComment(post){

                let mess = {
                    ...this.params,
                    ...post
                }

                this.loadingCommentary = true

                this.axios.post('commentary/commentaries/'+ post.id +'/update', mess).
                then(response => {
                    this.loadingCommentary = false
                    this.selectedEdit = false;
                    this.getComments();
                })

            },
            deleteComment(messageId) {
                // this.loadingCommentary = true
                this.loadingDelete = true
                let mess = {
                    ...this.params,
                    ...{ id: messageId }
                }
                this.axios.post('commentary/commentaries/'+ messageId +'/delete', mess).
                then(response => {
                    this.getComments()
                })
                // this.loadingCommentary = false
                this.loadingDelete = false
            },
            getSMU(){

                let result = [];
                let smu = state.globalParams.competency

                if(smu) {
                    for (let index = 0; index < this.optionsSMU.length; index++) {

                        for (let x = 0; x < smu.length; x++) {

                            if(this.optionsSMU[index].filter_id == smu[x]){
                                this.smuSelected.push(this.optionsSMU[index])
                            }

                        }

                    }
                }

            },
            smuFiltered(){

                this.axios.get('filters/competencyIdWithRegionId', {
                    params: this.params
                }).then(response => {
                    this.optionsSMU = response.data.data
                    this.getSMU();
                })

            },
            getSector(){

                let result = [];
                let sector = state.globalParams.sector

                if(sector) {
                    for (let index = 0; index < this.optionsSector.length; index++) {

                        for (let x = 0; x < sector.length; x++) {

                            if(this.optionsSector[index].filter_id == sector[x]){
                                this.sectorSelected.push(this.optionsSector[index])
                            }

                        }

                    }
                }

            },
            sectorFiltered(){

                this.axios.get('filters/sector', {
                    params: this.params
                }).then(response => {
                    this.optionsSector = response.data.data
                    this.getSector();
                })

            },
            getServiceLine() {
                this.loading = true;
                this.axios.get('pipeline/service_line', { params: this.params })
                    .then(response => {
                        this.sectors = response.data.data.sectors
                    })
            },
            handleSearch: _.debounce(function() {
                this.asyncFind();
            },300),
            asyncFind (query, id) {
                if (cancel !== undefined) {
                    cancel();
                }
                this.searchFilter(id, query)
            },
            searchFilter(j, search) {

                this.loadingSMU = true;

                this.axios({
                    method:"get",
                    url: '/filters/competencyIdWithRegionId',
                    cancelToken: new CancelTokenPipeline(function executor(c) {
                        cancel = c;
                    }),
                    params:{filter_name:search, ...this.oppParams}
                }).then( response => {
                    this.optionsSMU = response.data.data
                    this.loadingSMU = false;
                })

            },
        },
        watch: {
            param_pipeline_name(){ 
                this.preApiCall() 
            },
            params(n, o) {
                this.page = 1
                this.preApiCall()
            },
            topParams() {
                this.page = 1
                this.visibleFilters = false
                this.getFilters();
                this.preApiCall()
            },
            opportunitySelected(n,o){

                if(n){

                    this.getComments();
                }

            },
            smuSelected(n,o){

                this.axios({
                    method: "get",
                    url: "pipeline/opportunities",
                    cancelToken: new CancelTokenPipeline(function executor(c) {
                        cancel = c;
                    }),
                    params: {
                        ...this.topParams,
                        page: this.page
                    }
                }).then(response => {
                    if (this.page === 1) {
                        this.OpportunitiesList = response.data
                    }
                    else {
                        this.OpportunitiesList.meta = response.data.meta;
                        for (let index = 0; index < response.data.data.length; index++) {
                            this.OpportunitiesList.data.push(response.data.data[index])
                        }
                    }
                    this.loading = false
                }).catch(err => {
                    this.loading = false
                    this.results = err.message;
                })

            }
        },
        mounted(){
            this.preApiCall();
            this.getFilters();
            this.getServiceLine();
        }
    }

</script>

<style>

    .multiselect--disabled .multiselect__select{
        background: transparent !important;
        color: transparent !important
    }

    .multiselect--disabled{
        background: transparent !important;
    }

</style>